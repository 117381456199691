import { OptionItem } from '@/common/types';
import useTranslation from '@/utils/i18n/useTranslation';
import { Button, Stack } from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { MdChevronLeft as BackIcon, MdChevronRight as NextIcon } from 'react-icons/md';
import { HashArgs } from '.';

type Props = {
  rootOptions: OptionItem[];
  parentOptionsMap: HashArgs;
  defaultOption?: OptionItem | null;
  onSelect: (option: OptionItem) => void;
};

const MultipleLayerSelectOption: FC<Props> = (props: Props) => {
  const { rootOptions, onSelect, parentOptionsMap, defaultOption } = props;
  const { t } = useTranslation();

  const [options, setOptions] = useState<OptionItem[]>([]);
  const [selectedOption, setSelectedOption] = useState<OptionItem | null>(null);
  const [layer, setLayer] = useState<OptionItem | null>(null);

  useEffect(() => {
    setOptions(rootOptions);
  }, [rootOptions]);

  useEffect(() => {
    if (defaultOption) {
      setSelectedOption(defaultOption);
      const currentLayer = parentOptionsMap[defaultOption.id];
      if (currentLayer && currentLayer.children) {
        setLayer(currentLayer);
        setOptions(currentLayer.children);
      }
    } else {
      setSelectedOption(null);
      setLayer(null);
      setOptions(rootOptions);
    }
  }, [defaultOption, parentOptionsMap, rootOptions]);

  const handleBack = () => {
    if (layer) {
      const previousLayer = parentOptionsMap[layer.id];
      if (previousLayer && previousLayer.children) {
        setLayer(previousLayer);
        setOptions(previousLayer.children);
        return;
      }
    }
    resetLayer();
  };

  const handleSelect = (option: OptionItem) => {
    if (option.children && option.children.length > 0) {
      setLayer(option);
      setOptions(option.children);
    } else {
      setSelectedOption(option);
      onSelect(option);
    }
  };

  const resetLayer = () => {
    setLayer(null);
    setOptions(rootOptions);
  };

  return (
    <>
      {layer && (
        <Stack ml={0} spacing='0'>
          <Button
            variant='ghost'
            colorScheme='neutral'
            pl={1}
            color='neutral.500'
            fontWeight='500'
            justifyContent='start'
            leftIcon={<BackIcon color='neutral.500' />}
            onClick={() => handleBack()}
          >
            {t('actions.back')}
          </Button>

          {!layer.isSelectDisabled && (
            <Button
              variant='ghost'
              colorScheme='neutral'
              pl={1}
              w='100%'
              fontWeight='500'
              justifyContent='space-between'
              color={selectedOption?.id === layer.id ? 'primary.500' : ''}
              bg={selectedOption?.id === layer.id ? 'primary.50' : ''}
              onClick={() => onSelect(layer)}
            >
              {layer.label}（{t('parent-item')}）
            </Button>
          )}
        </Stack>
      )}
      <Stack ml={layer ? 5 : 0} spacing='0'>
        {options
          .filter(
            (option) => !option.isSelectDisabled || (option.children && option.children.length > 0)
          ) // 選択できないオプションで、子要素が存在しない場合は表示しない
          .map((option) => (
            <Button
              variant='ghost'
              colorScheme='neutral'
              key={option.id}
              w='100%'
              justifyContent='space-between'
              fontWeight='500'
              rightIcon={
                option.children && option.children.length > 0 ? (
                  <NextIcon color='neutral.500' />
                ) : (
                  <></>
                )
              }
              color={selectedOption?.id === option.id ? 'primary.600' : ''}
              bg={selectedOption?.id === option.id ? 'primary.100' : 'transparent'}
              onClick={() => handleSelect(option)}
            >
              {option.label}
            </Button>
          ))}
      </Stack>
    </>
  );
};

export default MultipleLayerSelectOption;
