import { OptionItem } from '@/common/types';
import { Box, Button, Highlight, Stack, Text } from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { MdExpandMore as DownIcon } from 'react-icons/md';

type Props = {
  searchText: string;
  currentOptions: OptionItem[];
  selectedOption: OptionItem | undefined;
  onSelect: (option: OptionItem) => void;
};

const MultipleLayerSearchOption: FC<Props> = (props: Props) => {
  const { onSelect, selectedOption, currentOptions, searchText } = props;

  const [options, setOptions] = useState<OptionItem[]>([]);

  useEffect(() => {
    setOptions(currentOptions);
  }, [currentOptions]);

  const handleSelect = (option: OptionItem) => {
    onSelect(option);
  };

  return (
    <>
      {options.map((option) => (
        <Box key={option.id} w='100%'>
          <>
            <Button
              variant='ghost'
              colorScheme='gray'
              w='100%'
              fontWeight='500'
              justifyContent='space-between'
              rightIcon={
                option.children && option.children.length > 0 ? (
                  <DownIcon color='neutral.500' />
                ) : (
                  <></>
                )
              }
              bg={option.id === selectedOption?.id ? 'primary.600' : 'transparent'}
              onClick={() => handleSelect(option)}
            >
              <Text>
                <Highlight query={searchText} styles={{ color: 'primary.600' }}>
                  {option.label}
                </Highlight>
              </Text>
            </Button>
            {option.matchedChild && (
              <Stack ml={5}>
                <MultipleLayerSearchOption
                  onSelect={handleSelect}
                  searchText={searchText}
                  selectedOption={selectedOption}
                  currentOptions={option.matchedChild}
                />
              </Stack>
            )}
          </>
        </Box>
      ))}
    </>
  );
};

export default MultipleLayerSearchOption;
